import "./bookListPage.css";
import { motion } from "framer-motion";
import { ErrorBoundary } from "react-error-boundary";
import BookList from "../../components/bookList/bookList";
import ErrorFallback from "../../components/errorHandle/errorHandle";
import Quotes from "../../quotes/quotes";

const BookListPage = (props) => {
  let searchInfo = props.location.state || {
    searchString: "",
    bookId: null,
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div className="banner">
          <Quotes />
        </div>
        <BookList searchParameter={searchInfo} />
      </motion.div>
    </ErrorBoundary>
  );
};

export default BookListPage;
