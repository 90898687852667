import "./footer.css";

const moreInfo = () => {
  window.open(
    "https://docs.google.com/forms/d/e/1FAIpQLSfytxSV8Xn1pFLIJchcxw_gRs1oX_pVTvaHDUQSdsQs8VeziA/viewform"
  );
};

const Footer = ({ setModalOpen }) => {
  const showPopUp = () => {
    setModalOpen(true);
  };
  return (
    <div className="footer">
      <p className="footer_link" onClick={showPopUp}>
        אודות
      </p>
      <p className="footer_link" onClick={moreInfo}>
        מה חשבתם עלינו?
      </p>
    </div>
  );
};

export default Footer;
