import "./logo.css";
import worm from "../../img/Robo_BookWorm_logo_v2-03.png";
import { Link } from "react-router-dom";

const Logo = () => (
  <Link to="/">
    <div className="head">
      <img className="worm" src={worm} alt="bookworm" />
    </div>
  </Link>
);

export default Logo;
