import "./singlebooksView.css";
import React from "react";
import { BookEditionResult } from "../../apiCalls/models";
import bookDefaultImage from "../../img/Book.png";

const SingleEditionDetails: React.FC<{ bookEditionInfo: BookEditionResult }> = (
  props
) => {
  let book = props.bookEditionInfo;
  let bookEditionImage = book.ThumbnailUrl || bookDefaultImage;

  return (
    <div className="singlebookView">
      <img
        className="singlebookImg"
        src={bookEditionImage}
        alt="singleBookImage"
      />
      <h2 className="singlebookName">{book.DisplayName}</h2>
      <p>{book.DisplayAuthors}</p>
    </div>
  );
};

export default SingleEditionDetails;
