import "./searchForm.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import BooksApi2 from "../../apiCalls/BooksApi2";
import Logo from "../logo/logo";
import SearchIcon from "../../img/Magnifying_glass_icon-04.png";

// Search State Machine, takes care of rate limit and reordering
class SearchSM {

  RateLimitMS = 500;
  LastRequestTimeMS = 0;

  lastTimeOut = null;
  RateShapeRequest = (request) => {
    if (this.lastTimeOut !== null)
      clearTimeout(this.lastTimeOut);

    let now = Date.now();
    this.lastTimeOut = setTimeout(() => {this.LastRequestTimeMS = Date.now(); request()},
     Math.max(0,  this.LastRequestTimeMS + this.RateLimitMS  - now));
  }

  lastReqId = 0;
  SendRequest = (request, responseCB) => {
    let reqId = ++this.lastReqId;
    this.RateShapeRequest(() => request()
    .then(r => {
      // Ignore any request that is not the last one
      if (reqId !== this.lastReqId)
        return;

      responseCB(r)
    }))
  }

  CancelRequests = () => {
    //ignore all previous requests
    this.lastReqId++;
  }
}

const SearchForm: React.FC = (props) => {
  const minCharsToSearch: number = 3;
  const history = useHistory();
  const searchFieldRef = useRef<HTMLInputElement>(null);
  const [suggestions, setSuggestions] = useState([]);
  const [inputMode, setInputMode] = useState("idle");
  const [searchSM, setSearchSM] = useState(new SearchSM())

  const StopSearch = () => {
    setSuggestions([]);
    setInputMode("idle");
    searchSM.CancelRequests();
  }


  const SearchSubmit = () => {
    const BookNameEntered = searchFieldRef.current!.value;

    let inputReader = {
      searchString: BookNameEntered,
      bookId: null,
    };
    history.push({
      pathname: "/",
      state: inputReader,
    });
    StopSearch();
  };

  const onClickSubmit = (bookId) => {
    let inputReader = { searchString: null, bookId: bookId };
    history.push({
      pathname: "/",
      state: inputReader,
    });

    StopSearch();
  };

  const keyBoardSubmit = (event) => {
    if (event.key === "Enter") {
      SearchSubmit();
    }
  };

  const onChangeInput = async () => {
    const BookNameEntered = searchFieldRef.current!.value;
    if (BookNameEntered.length < minCharsToSearch) {
      StopSearch();
    } else {
      setInputMode("pending");
      setSuggestions([]);
     searchSM.SendRequest(() => BooksApi2.SearchBooks(BookNameEntered, 10), (res) => {
        setInputMode("completed");
        setSuggestions(res.Result);
      })
      
    }
  };

  const closeSuggestions = () => {
    StopSearch();
  };

  const loader = (
    <div className="show-spinner">
      <Loader
        type="ThreeDots"
        color="#ecba04"
        height={50}
        width={50}
        timeout={8000} //8 secs
      />
    </div>
  );

  const noResult = <h1 className="show-spinner">לא נמצאה תוצאה מתאימה</h1>;

  const suggestionList = (
    <div className="suggestionList">
      {suggestions.map((suggestion, i) => (
        <div
          key={i}
          onClick={() => {
            onClickSubmit(suggestion.BookId);
          }}
          className="suggestions"
        >
          {suggestion.DisplayName} / {suggestion.DisplayAuthors}
        </div>
      ))}
    </div>
  );

  return (
    <div className="topHeader" onClick={closeSuggestions}>
      <div>
        <div className="inputHolder">
          <input
            onChange={onChangeInput}
            type="search"
            id="text"
            placeholder="  חיפוש..."
            ref={searchFieldRef}
            onKeyPress={keyBoardSubmit}
          ></input>
          <img
            src={SearchIcon}
            alt="SearchIcon"
            onClick={SearchSubmit}
            className="SearchIcon"
          />
        </div>
        {inputMode === "pending" ? loader : null}

        {inputMode === "completed" && suggestions.length === 0
          ? noResult
          : null}

        {inputMode === "completed" && suggestions.length > 0
          ? suggestionList
          : null}
      </div>
      <div>
        <Logo />
      </div>
    </div>
  );
};

export default SearchForm;
