import "./singlebooksView.css";
import React from "react";
import { BookResult } from "../../apiCalls/models";
import bookDefaultImage from "../../img/Book.png";

const SingleBookDetails: React.FC<{ bookInfo: BookResult }> = (props) => {
  let book = props.bookInfo;
  let bookEditionImage = book.ThumbnailUrl || bookDefaultImage;

  return (
    <div className="singlebookView">
      <img className="singlebookImg" src={bookEditionImage} alt="Book_Image" />
      <h2 className="singlebookName">{book.DisplayName}</h2>
      <p>{book.DisplayAuthors}</p>
    </div>
  );
};

export default SingleBookDetails;
