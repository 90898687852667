import "./listbooksView.css";
import { Link } from "react-router-dom";
import { BookEditionResult } from "../../apiCalls/models";
import bookDefaultImage from "../../img/Book.png";
import RouteConstants from "../../routing/RouteConstants";
import { formatEdition, formatPriceRange } from "../../formatters/formatters";

const BookEditionDetails: React.FC<{
  data: BookEditionResult;
  key: any;
}> = (props) => {
  let book = props.data;
  let bookImage = book.ThumbnailUrl || bookDefaultImage;

  return (
    <Link to={`/${RouteConstants.BookPagePAth}/${props.data.BookId}`}>
      <div className="bookView">
        <img className="bookImg" src={bookImage} alt="bookImage" />
        <h2 className="bookName">{book.DisplayName}</h2>
        <p>{book.DisplayAuthors}</p>
        <p> {formatPriceRange(book.MinPrice, book.MaxPrice)} ש"ח</p>
      </div>
    </Link>
  );
};

export default BookEditionDetails;
