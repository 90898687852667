import "./pagination.css";
import { useEffect } from "react";

const Pagination = ({
  changePageCB,
  RenderComponent,
  currentPage,
  pageLimit,
  pageData,
  pageCount,
}) => {
  if (pageCount < pageLimit) pageLimit = pageCount;

  //   scroll to top on page change
  useEffect(() => {
    window.scrollTo({ behavior: "smooth", top: "0px" });
  });

  const goToPage = (pageNum) => {
    changePageCB(pageNum);
  };

  const goToNextPage = () => goToPage(currentPage + 1);

  const goToPreviousPage = () => goToPage(currentPage - 1);

  const changePage = (event) => {
    const pageNumber = Number(event.target.textContent);
    goToPage(pageNumber);
  };

  const getPaginationGroup = () => {
    let start = 0;
    currentPage >= 4 && currentPage ? (start = currentPage - 3) : (start = 0);
    return new Array(pageLimit).fill().map((_, idx) => start + idx + 1);
  };

  return (
    <div className="paginatedPage">
      {/* show the books, 20 books at a time */}
      <div className="dataContainer">
        {pageData.map((d, idx) => (
          <RenderComponent key={idx} data={d} />
        ))}
      </div>

      {/* show the pagiantion
        it consists of next and previous buttons
        along with page numbers, in our case, 5 page
        numbers at a time
    */}
      <div className="pagination">
        {/* previous button */}
        <button
          onClick={goToPreviousPage}
          className={`prev ${currentPage === 1 ? "disabled" : ""}`}
        >
          הקודם
        </button>

        {/* show page numbers */}
        {getPaginationGroup().map((item, index) => {
          return (
            <button
              key={index}
              onClick={changePage}
              className={`paginationItem ${
                currentPage === item ? "active" : ""
              }`}
            >
              <span>{item}</span>
            </button>
          );
        })}

        {/* next button */}
        <button
          onClick={goToNextPage}
          className={`next ${currentPage === pageCount ? "disabled" : ""}`}
        >
          הבא
        </button>
      </div>
    </div>
  );
};

export default Pagination;
