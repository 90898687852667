import "./bookEditionPage.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { motion } from "framer-motion";
import { ErrorBoundary } from "react-error-boundary";
import SingleEditionDetails from "../../components/singlebooksView/singlebooksView";
import { BookEditionResult, BookItemResult } from "../../apiCalls/models";
import BooksApi2 from "../../apiCalls/BooksApi2";
import ErrorFallback from "../../components/errorHandle/errorHandle";
import PerBookEditionsList from "../../components/perBookEditionsList/perBookEditionsList";
import BookItemView from "../../components/bookItemView/bookItemView";

const BookEditionPage: React.FC = () => {
  let { id } = useParams<{ id: string }>();
  const [mainEdition, setMainEdition] = useState<{
    isLoaded: Boolean;
    book?: BookEditionResult;
  }>({
    isLoaded: false,
    book: undefined,
  });
  const [otherEditions, setOtherEditions] = useState<BookEditionResult[]>([]);
  const [bookItems, setBookItems] = useState<BookItemResult[]>([]);
  const [bookId, setBookID] = useState("");

  useEffect(() => {
    BooksApi2.GetBookEdition(id).then((mainEdition) => {
      setMainEdition({ isLoaded: true, book: mainEdition });
      setBookID(mainEdition.BookId);
      BooksApi2.GetBookEditionsByBookId(mainEdition.BookId).then(
        (moreEditions) => {
          setOtherEditions(moreEditions.Result);
        }
      );
    });
    BooksApi2.GetBookItemsByBookEditionId(id).then((result) =>
      setBookItems(result.Result)
    );
  }, [id]);

  let bookElement = (
    <div className="loader-position">
      <Loader
        type="Circles"
        color="#ecba04"
        height={200}
        width={200}
        timeout={8000} //8 secs
      />
    </div>
  );
  if (mainEdition.isLoaded) {
    bookElement = mainEdition.book ? (
      <div>
        <div className="mainEditionView">
          <SingleEditionDetails bookEditionInfo={mainEdition.book} />
          <div>
            <h1>מהדורות ספרים נוספות</h1>
            {otherEditions.length > 0 && (
              <PerBookEditionsList
                editionsList={otherEditions}
                currentEditionId={id}
              />
            )}
          </div>
        </div>
        <h1>ספרים לרכישה</h1>
        <div className="bookLinkList">
          {bookItems.map((bi) => (
            <BookItemView bookItem={bi} key={bi.BookId} />
          ))}
        </div>
      </div>
    ) : (
      <h1>Book not found</h1>
    );
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div className="bookEditionPageBody">
          <div>{bookElement}</div>
        </div>
      </motion.div>
    </ErrorBoundary>
  );
};

export default BookEditionPage;
