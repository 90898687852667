import "./bookList.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { useEffect, useState } from "react";
import BookEditionDetails from "../listbooksView/listbooksView";
import { BookEditionResult } from "../../apiCalls/models";
import BooksApi2 from "../../apiCalls/BooksApi2";
import Pagination from "../pagination/pagination";

const BookList: React.FC<{
  searchParameter: { searchString: string; bookId: string };
}> = (props) => {
  const [receivedBookEditions, setReceivedBookEditions] = useState<
    BookEditionResult[]
  >([]);
  const [error, setError] = useState("");
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [searchInProgress, setSearchInProgress] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageLimit = 5;
  const resultLimit = 20;
  const handleBooksData = (res) => {
    if (res !== null) {
      setSearchInProgress(false);
      setReceivedBookEditions(res.Result);
      setTotalItemCount(res.Count);
    }
  };

  const handleError = (error) => {
    console.log("error fetching: ", error);
    setError("something went wrong while requesting books");
  };
  const changePage = (number) => {
    setCurrentPage(number);
    if (props.searchParameter.searchString !== null) {
      BooksApi2.SearchBooks(
        props.searchParameter.searchString,
        resultLimit,
        (number - 1) * resultLimit,
        true
      )
        .then((res) => handleBooksData(res))
        .catch((error) => handleError(error));
    } else {
      BooksApi2.GetBookEditionsByBookId(
        props.searchParameter.bookId,
        resultLimit,
        (number - 1) * resultLimit
      )
        .then((res) => handleBooksData(res))
        .catch((error) => handleError(error));
    }
    setSearchInProgress(true);
  };

  useEffect(() => {
    changePage(1);
  }, [props.searchParameter]);

  if (error) {
    return <h1 className="error_message">{error}</h1>;
  }

  return (
    <div>
      {searchInProgress ? (
        <div className="loader-position">
          <Loader
            type="Circles"
            color="#ecba04"
            height={200}
            width={200}
            timeout={8000} //8 secs
          />
        </div>
      ) : receivedBookEditions.length > 0 ? (
        <Pagination
          changePageCB={changePage}
          RenderComponent={BookEditionDetails}
          currentPage={currentPage}
          pageLimit={pageLimit}
          pageData={receivedBookEditions}
          pageCount={Math.floor(totalItemCount / resultLimit) + 1}
        />
      ) : (
        <h1 className="error_message">Found No Books To Display</h1>
      )}
    </div>
  );
};

export default BookList;
