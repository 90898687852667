import "../bookEditionPage/bookEditionPage.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { motion } from "framer-motion";
import { ErrorBoundary } from "react-error-boundary";
import SingleBookDetails from "../../components/singlebooksView/singlebook";
import {
  BookEditionResult,
  BookItemResult,
  BookResult,
} from "../../apiCalls/models";
import BooksApi2 from "../../apiCalls/BooksApi2";
import ErrorFallback from "../../components/errorHandle/errorHandle";
import PerBookEditionsList from "../../components/perBookEditionsList/perBookEditionsList";
import BookItemView from "../../components/bookItemView/bookItemView";

export default function AllEditionsPage() {
  let { id } = useParams<{ id: string }>();
  const [book, setBook] = useState<{
    isLoaded: Boolean;
    bookData?: BookResult;
  }>({
    isLoaded: false,
    bookData: undefined,
  });
  const [otherEditions, setOtherEditions] = useState<BookEditionResult[]>([]);
  const [bookItems, setBookItems] = useState<BookItemResult[]>([]);

  useEffect(() => {
    //list of editions for editions drop down
    BooksApi2.GetBookEditionsByBookId(id).then((editions) => {
      setOtherEditions(editions.Result);
    });
    //list of items for item list at the bottom of the page
    BooksApi2.GetBookItemsByBookId(id).then((result) =>
      setBookItems(result.Result)
    );
    //display book data
    BooksApi2.GetBook(id).then((bookInfo) =>
      setBook({ isLoaded: true, bookData: bookInfo })
    );
  }, [id]);

  let bookElement = (
    <div className="loader-position">
      <Loader
        type="Circles"
        color="#ecba04"
        height={200}
        width={200}
        timeout={8000} //8 secs
      />
    </div>
  );
  if (book.isLoaded) {
    bookElement = book.bookData ? (
      <div>
        <div className="mainEditionView">
          <SingleBookDetails bookInfo={book.bookData} />
          <div>
            <h1>מהדורות ספרים נוספות</h1>
            {otherEditions.length > 0 && (
              <PerBookEditionsList editionsList={otherEditions} isAllEditions />
            )}
          </div>
        </div>
        <h1>ספרים לרכישה</h1>
        <div className="bookLinkList">
          {bookItems.map((bi) => (
            <BookItemView bookItem={bi} key={Math.random()} />
          ))}
        </div>
      </div>
    ) : (
      <h1>Book not found</h1>
    );
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div className="bookEditionPageBody">{bookElement}</div>
      </motion.div>
    </ErrorBoundary>
  );
}
