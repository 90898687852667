import "./quotes.css";
import { useState, useEffect } from "react";

const Quotes = () => {
  const quotesArray: string[] = [
    '"You can never get a cup of tea large enough or a book long enough to suit me.” – C.S. Lewis',
    '"It is only with the heart that one can see rightly; what is essential is invisible to the eye.” — Antoine de Saint-Exupéry, The Little Prince',
    '"Fairy tales are more than true: not because they tell us that dragons exist, but because they tell us that dragons can be beaten." - Neil Gaiman',
    '"Books are good company, in sad times and happy times, for books are people – people who have managed to stay alive by hiding between the covers of a book." - E.B. White',
    '"I find television very educating. Every time somebody turns on the set, I go into the other room and read a book." - Groucho Marx',
    '"If you would tell me the heart of a man, tell me not what he reads, but what he rereads." - Francois Mauriac',
    '"A children’s story that can only be enjoyed by children is not a good children’s story in the slightest." - C.S. Lewis',
    '"Reading one book is like eating one potato chip." - Diane Duane',
    '"In the case of good books, the point is not to see how many of them you can get through, but rather how many can get through to you." - Mortimer J. Adler',
    '"Libraries will get you through times of no money better than money will get you through times of no libraries." - Anne Herbert',
    '"Children know perfectly well that unicorns aren’t real, but they also know that books about unicorns, if they are good books, are true books." - Ursula K. LeGuin'
  ];

  const quotesDelay = 10000;
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      if (counter < quotesArray.length - 1) {
        setCounter(counter + 1);
      } else {
        setCounter(0);
      }
    }, quotesDelay);
    return () => clearInterval(timer);
  }, [counter, quotesArray.length]);
  return <p className="p_quote">{quotesArray[counter]}</p>;
};

export default Quotes;
