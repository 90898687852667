export class PagedResult<Type> {
  Offset: number; // 0-based offset of results
  Limit: number; // number of results returned (equal to resultLimit if provided)
  Count: number; // Total number of results for this query
  Result: Array<Type>;
}

export class BookResult {
  BookId: string; // [Primary Key] this is an opaque key; you can't make any assumptions on its structure; order or anything
  DisplayName: string; // Book Name
  DisplayAuthors: string;
  ThumbnailUrl: string;
  MinPrice: number;
  MaxPrice: number;
}

export class BookEditionResult {
  BookEditionId: string; // [Primary Key] this is an opaque key, you can't make any assumptions on its structure, order or anything
  BookId: string; // this is an opaque key, you can't make any assumptions on its structure, order or anything
  DisplayName: string; // Book name
  DisplayAuthors: string;
  ThumbnailUrl: string;
  DisplayPublisher: string;
  PublishYear: number;
  MinPrice: number;
  MaxPrice: number;
}

export class BookItemResult {
  BookItemId: string; // [Primary Key] this is an opaque key, you can't make any assumptions on its structure, order or anything
  BookEditionId: string; // this is an opaque key, you can't make any assumptions on its structure, order or anything
  BookId: string; // this is an opaque key, you can't make any assumptions on its structure, order or anything
  Price: number;
  DisplayBookStore: string; // Display name of the book store
  DeepLink: string; // URL, pointing to the page item in the store
}

export const modelConstants = {
  UnknownPublisher: "__unknown__",
  UnknownPublishYear: -1
}
