import "./popup_modal.css";
import { useEffect } from "react";
import Modal from "react-modal";

const PopupModal = ({ modalOpen, setModalOpen }) => {
  useEffect(() => {
    Modal.setAppElement("body");
    let firstVisit = localStorage.getItem("beenHereBefore");
    if (firstVisit === null) {
      localStorage.setItem("beenHereBefore", "yes");
      setModalOpen(true);
    }
  }, []);

  function closeModal() {
    setModalOpen(false);
  }

  return (
    <div>
      <Modal
        isOpen={modalOpen}
        onRequestClose={closeModal}
        className="modal"
        overlayClassName="overlay"
        contentLabel="ברוכים הבאים"
      >
        <button onClick={closeModal}>סגור</button>
        <h1>ברוכים הבאים לפיילוט של אפליקציית חיפוש ספרי יד שנייה</h1>

        <div className="popUp">איזה פיילוט ?</div>
        <div className="popUp">
          האפליקציה נמצאת בשלב הפיילוט, בו אנחנו מבקשים מקומץ אנשים נבחרים
          ויחידי סגולה (כן, זה כולל אותך) לנסות את האפליקציה ולהגיד לנו מה הם
          חושבים עליה, מה כדאי לשפר ואיזה פיצ'רים מגניבים הם רוצים לראות. אם
          הגעת לכאן בטעות, לא נורא, כיף פה.{" "}
        </div>
        <div className="popUp">מה עושים כאן ? </div>
        <div className="popUp">
          אפשר לדפדף ברשימת הספרים, אבל אם את\ה מחפש\ת משהו ספציפי כנראה שעדיף
          להשתמש בחלונית החיפוש למעלה, להקליד חלק (או הכל) משם ספר או סופר ואז
          לבחור את הספר והמהדורה שמעניינים אותך. בעמוד המהדורה תוכל\י לראות את
          רשימת החנויות שמציעות את המהדורה המבוקשת והמחיר שלה, קליק נוסף יקח
          אותך ישר לעמוד המתאים בחנות בו תוכל להזמין את הספר.
        </div>
        <div className="popUp">איך זה עובד ?</div>
        <div className="popUp">
          תולעת הספרים הרובוטית שלנו (Robo Bookworm) זוחלת לפחות פעם ביום דרך
          מספר חנויות ספרים מקוונות (כרגע שלוש, נוסיף עוד בקרוב) ומביאה לכם\ן
          לכאן את כל מה שצריך כדי למצוא בקלות את הספר הבא. אם בא לך לספר לנו איך
          היה, יש לנו גם טופס משוב למטה בקישורים.
        </div>
      </Modal>
    </div>
  );
};

export default PopupModal;
