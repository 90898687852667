import "./header.css";
import Logo from "../logo/logo";
import SearchForm from "../searchForm/searchForm";

const Header = () => {
  return (
    <div>
      <Logo />
      <SearchForm />
    </div>
  );
};

export default Header;
