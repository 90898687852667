import "./App.css";
import { useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import BookListPage from "./containers/bookListPage/bookListPage";
import BookEditionPage from "./containers/bookEditionPage/bookEditionPage";
import Header from "./components/header/header";
import Footer from "./components/footer/footer";
import PopupModal from "./components/popup_modal/popup_modal";
import AllEditionsPage from "./containers/AllEditionsPage/AllEditionsPage";

function App() {
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <div>
      <Router>
        <Header />
        <AnimatePresence>
          <Switch>
            <Route exact path="/" component={BookListPage} />
            <Route path="/BookEditionPage/:id" component={BookEditionPage} />
            <Route path="/AllEditionsPage/:id" component={AllEditionsPage} />
          </Switch>
        </AnimatePresence>
        <PopupModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
        <Footer setModalOpen={setModalOpen} />
      </Router>
    </div>
  );
}

export default App;
