import "./perBookEditionsList.css";
import { BookEditionResult } from "../../apiCalls/models";
import { formatEdition, formatPriceRange } from "../../formatters/formatters";
import RouteConstants from "../../routing/RouteConstants";
import { useHistory } from "react-router-dom";

const PerBookEditionsList: React.FC<{
  isAllEditions?: boolean;
  editionsList: BookEditionResult[];
  currentEditionId?: string;
}> = (props) => {
  let history = useHistory();

  let selectedValue = props.isAllEditions
    ? "all_editions"
    : props.currentEditionId;

  const changeEdition = (event) => {
    if (event.target.value === "all_editions") {
      history.push(
        `/${RouteConstants.BookPagePAth}/${props.editionsList[0].BookId}`
      );
    } else {
      history.push(
        `/${RouteConstants.BookEditionPagePath}/${event.target.value}`
      );
    }
  };

  let rows = props.editionsList.map((be) => (
    <option key={be.BookEditionId} value={be.BookEditionId}>
      {formatEdition(be.DisplayPublisher, be.PublishYear, true)},{" "}
      {formatPriceRange(be.MinPrice, be.MaxPrice)} ש"ח
    </option>
  ));

  return (
    <select onChange={changeEdition} value={selectedValue}>
      {rows}
      <option value="all_editions">כל המהדורות</option>
    </select>
  );
};

export default PerBookEditionsList;
