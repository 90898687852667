import {
  PagedResult,
  BookEditionResult,
  BookResult,
  BookItemResult,
} from "./models";

class BooksApi2 {
  // This function is used for incremental search in search window
  //   searchExpression: the search string inserted by a user, an empty string means "all"
  //   resultLimit: the maximum number of results to return
  //   offset: 0-based offset of results
  static SearchBooks = async (
    searchExpression: string,
    resultLimit: number = 10,
    offset: number = 0,
    getCount: boolean = false
  ): Promise<PagedResult<BookResult>> => {
    // eslint-disable-next-line no-useless-concat
    const requestUrl =
      `${process.env.REACT_APP_BOOK_SERVICE_URL}/Books2` +
      "?" +
      [
        `Limit=${resultLimit}`,
        `Offset=${offset}`,
        `GetCount=${getCount}`,
        searchExpression !== null
          ? `&SearchExpression=${encodeURIComponent(searchExpression)}`
          : null,
      ]
        .filter((s) => s !== null)
        .join("&");

    const res = await fetch(requestUrl);
    const pagedResult: PagedResult<BookResult> = await res.json();
    return pagedResult;
  };

  // This function is used to display the book edition list (main page)
  //   searchExpression: the search string inserted by a user, an empty string means "all"
  //   resultLimit: the maximum number of results to return
  //   offset: 0-based offset of results
  static SearchEditions = async (
    searchExpression: string,
    resultLimit: number = 20,
    offset: number = 0
  ): Promise<PagedResult<BookEditionResult>> => {
    const requestUrl =
      `${process.env.REACT_APP_BOOK_SERVICE_URL}/BookEditions2` +
      `?Limit=${resultLimit}` +
      `&Offset=${offset}` +
      (searchExpression === null || searchExpression === ""
        ? ""
        : `&SearchExpression=${encodeURIComponent(searchExpression)}`);

    const res = await fetch(requestUrl);
    if (!res.ok) return null;
    const pagedResult: PagedResult<BookEditionResult> = await res.json();
    return pagedResult;
  };

  // This function is used to generate the Book Editions Page
  //   bookId - same book ID that was returned from previous queries
  //   resultLimit - the maximum number of results to return
  //   offset: 0-based offset of results

  // ######To Do - in case there is no compatible Id - throw exception - error handling
  static GetBookEditionsByBookId = async (
    bookId: string,
    resultLimit: number = 10,
    offset: number = 0
  ): Promise<PagedResult<BookEditionResult>> => {
    const requestUrl =
      `${process.env.REACT_APP_BOOK_SERVICE_URL}/BookEditions2` +
      `?BookId=${encodeURIComponent(bookId)}` +
      `&Limit=${resultLimit}` +
      `&Offset=${offset}`;
    const res = await fetch(requestUrl);
    if (!res.ok) return null;
    let editionsList: PagedResult<BookEditionResult> = await res.json();

    return editionsList;
  };

  // This function is used to generate the list in the Book Editions page
  //   bookEditionId - same book edition ID that was returned from previous queries
  //   resultLimit - the maximum number of results to return
  //   offset: 0-based offset of results
  static GetBookItemsByBookEditionId = async (
    bookEditionId: string,
    resultLimit: number = 10,
    offset: number = 0
  ): Promise<PagedResult<BookItemResult>> => {
    const requestUrl =
      `${process.env.REACT_APP_BOOK_SERVICE_URL}/BookItems2` +
      `?BookEditionId=${encodeURIComponent(bookEditionId)}` +
      `&Limit=${resultLimit}` +
      `&Offset=${offset}`;
    const res = await fetch(requestUrl);
    let itemsList: PagedResult<BookItemResult> = await res.json();

    return itemsList;
  };

  // This function is used to generate the list in the Book Editions page
  //   bookEditionId - same book edition ID that was returned from previous queries
  //   resultLimit - the maximum number of results to return
  //   offset: 0-based offset of results
  static GetBookItemsByBookId = async (
    bookId: string,
    resultLimit: number = 10,
    offset: number = 0
  ): Promise<PagedResult<BookItemResult>> => {
    const requestUrl =
      `${process.env.REACT_APP_BOOK_SERVICE_URL}/BookItems2` +
      `?BookId=${encodeURIComponent(bookId)}` +
      `&Limit=${resultLimit}` +
      `&Offset=${offset}`;
    const res = await fetch(requestUrl);
    let itemsList: PagedResult<BookItemResult> = await res.json();

    return itemsList;
  };

  // This function is used to generate main part of the book edition page
  //   bookEditionId - same book edition ID that was returned from previous queries
  //   Returned Value: BookEditionResult or null if the book edition was not found
  static GetBookEdition = async (
    bookEditionId: string
  ): Promise<BookEditionResult> => {
    const requestUrl =
      `${process.env.REACT_APP_BOOK_SERVICE_URL}/BookEdition2/` +
      encodeURIComponent(bookEditionId);
    const res = await fetch(requestUrl);
    const edition: BookEditionResult = await res.json();

    return edition;
  };

  // This function is used to generate main part of the book page
  //   bookId - same book  ID that was returned from previous queries
  //   Returned Value: BookResul or null if the book edition was not found
  static GetBook = async (
    bookId: string
  ): Promise<BookResult> => {
    const requestUrl =
      `${process.env.REACT_APP_BOOK_SERVICE_URL}/Book2/` +
      encodeURIComponent(bookId);
    const res = await fetch(requestUrl);
    const book: BookResult = await res.json();

    return book;
  };
}

export default BooksApi2;
