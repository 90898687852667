import { modelConstants } from "../apiCalls/models";

export const formatEdition = (
  publisher: string,
  publishYear: number,
  displayUnknownPublisher: boolean
): string => {
  let result;
  if (displayUnknownPublisher === true) {
    result =
      publisher === modelConstants.UnknownPublisher
        ? "הוצאה לא ידועה"
        : publisher;
  } else {
    result = publisher === modelConstants.UnknownPublisher ? null : publisher;
  }
  if (publishYear !== modelConstants.UnknownPublishYear)
    result = result + ", " + publishYear;

  return result;
};

// This method is required to compare prices because in ts numbers are all floats,
// and floats comparison is tricky. so we are looking on less than one precent difference as equal
const identicalPrice = (p1: number, p2: number): boolean =>
  Math.abs(p1 - p2) < 0.01;

export const formatPriceRange = (min: number, max: number): string => {
  return identicalPrice(min, max) ? max.toString() : `${min}-${max}`;
};
