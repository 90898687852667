import "./bookItemView.css";
import { BookItemResult } from "../../apiCalls/models";

const BookItemView: React.FC<{ bookItem: BookItemResult }> = (props) => {
  let bi = props.bookItem;

  return (
    <div className="bookLinkDisplay">
      <p className="linkItem">{bi.DisplayBookStore}</p>
      <p className="linkItem"> מחיר {bi.Price} ש"ח</p>
      <button
        className="storeLinkButton"
        onClick={() => window.open(bi.DeepLink)}
      >
        לרכישה
      </button>
    </div>
  );
};

export default BookItemView;
